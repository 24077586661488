import { Box, Grid, Typography } from '@mui/material';
import LinkCard from './LinkCard';
import { useOutletContext } from "react-router-dom";

const linkCardItems = [
    { id: 1, title: 'QR Servicios', description: 'Genere el QR que necesite rellenando el siguiente formulario.', to: '/qr-services', buttonText: 'QR Servicios', tool_code: 'QR_GENERATOR_SERVICES' },
    { id: 2, title: 'QR Almacenes', description: 'Genere el QR para su almacen rellenando el siguiente formulario.', to: '/qr-warehouses', buttonText: 'Ir al formulario', tool_code: 'QR_GENERATOR_WAREHOUSES' },
];

const Home = () => {
    const { isAdmin, hasAccess, abilities } = useOutletContext();

    return (
        <>
            {/* Sección de encabezado */}
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <Typography variant='h5' component='h4' sx={{ paddingBottom: '1em' }}>
                    En esta pagina web puedes obtener los QR necesarios de Grupo Control
                </Typography>
            </Box>

            {/* Grid para mostrar las tarjetas de enlace */}
            <Grid
                container
                spacing={{ xs: 2, sm: 2 }}
                columns={{ xs: 4, sm: 4, md: 12 }}
                justifyContent="center"
                alignItems="stretch"
                sx={{ paddingTop: '3em', flexWrap: 'wrap', width: '100%' }} // Agregamos width: '100%' para expandir el Grid al ancho del padre
            >
                {isAdmin ? (
                    linkCardItems.map((item) => (
                        <Grid item xs={12} sm={6} md={4} key={item.id}>
                            <Box sx={{ paddingLeft: '2em', paddingRight: '1em', height: '100%' }}>
                                {/* Renderización del componente LinkCard */}
                                <LinkCard
                                    id={item.id}
                                    title={item.title}
                                    description={item.description}
                                    to={item.to}
                                    buttonText={item.buttonText}
                                />
                            </Box>
                        </Grid>
                    ))
                ) : (
                    linkCardItems.filter(item => abilities.includes(item.tool_code))
                        .map((item) => (
                            <Grid item xs={12} sm={6} md={4} key={item.id}>
                                <Box sx={{ paddingLeft: '2em', paddingRight: '1em', height: '100%' }}>
                                    {/* Renderización del componente LinkCard */}
                                    <LinkCard
                                        id={item.id}
                                        title={item.title}
                                        description={item.description}
                                        to={item.to}
                                        buttonText={item.buttonText}
                                    />
                                </Box>
                            </Grid>
                        ))
                )}
            </Grid>
        </>
    );
};

export default Home;
